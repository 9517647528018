<template>
  <div>
    <main-title :name="name"></main-title>
    <v-card flat>
      <v-card-text>
        <v-container>
          <a target="_blank" href="https://www.google.com/maps/place/Yorkshire+Bath+Resurfacing/@54.1345773,-1.0469037,11z/data=!4m2!3m1!1s0x487ed46e643d6ecd:0x945cf4dd5f8c040f">
            <img class="elevation-2" style="max-width: 100%" src="/images/ybrmap.png" alt="map">
          </a>


          <h2 class="mb-4 text-h5 mt-4">

            We'd love to hear from you. Why not give us a call ?

          </h2>

          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" md="5">
              <div class="pt-12">
                <h4 class="text-h6">
                  Additional Information
                </h4>

                <v-divider class="mt-2 mb-2"></v-divider>
                <p class="body-1">
                  <strong>Phone:</strong> 01653 648166 <br>
                  <strong>eMail:</strong> <a href="mailTo:sales@yorkshirebathresurfacing.co.uk">
                  sales@yorkshirebathresurfacing.co.uk
                </a> <br>
                  <strong>Post:</strong> Higfield Cottage, Terrington, York, YO60 6PA <br>
                </p>

                <h4 class="text-h6">
                  Social Media
                </h4>
                <a
                    target="_blank"
                    href="https://www.facebook.com/pages/Yorkshire-Bath-Resurfacing/172884329428790">
                  <v-icon>
                    mdi-facebook
                  </v-icon>
                </a>
              </div>
            </v-col>

            <v-col cols="12" md="7">
              <main-small-contact :showPhone="true" :dense="false" :isDark="false" :labels="true">

              </main-small-contact>
            </v-col>
            <v-col>

            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

    </v-card>
  </div>
</template>

<script>
  export default {
    data: () => ({
      item: 1,
      name: 'Contact Us'
    }),
    created() {

    },
    watch: {

    },
    methods: {

    }
  }
</script>
